import React, { useEffect, useState } from 'react';
import { useData } from '../../Shared/Context';
import StudentSettings from './StudentSettings';
import StudentHome from './StudentHome';
import Sparkshelf from './Sparkshelf';
import ProfileHeader from './ProfileHeader';
import ProfileButtons from './ProfileButtons';
import styles from './index.module.css';


const StudentProfile = (props) => {
    const { onMessageToParentFromComponent, onLogout, loadScreen, handleProjectClick } = props;
    const [showSettings, setShowSettings] = useState(false);
    const [showHome, setShowHome] = useState(true);
    const [showSparkshelf, setShowSparkshelf] = useState(false);
    const { activeStudent } = useData();
    
    useEffect(() => {
        if (loadScreen === 'bookshelf') {
            setShowSparkshelf(true);
            setShowHome(false);
        } else {    
            setShowHome(true);
            //fetchProjectCount();
        }
    }, []);

    const handleLogout = (logoutData) => {
        onLogout(logoutData);
    };

   

    
    const handleHeaderCallback = (key) => {
        
        handleClick(key);
    }

   


    const handleClick = (imageKey) => {

        switch (imageKey){
            case "settings":
                setShowHome(false);
                setShowSettings(true);
                setShowSparkshelf(false);
            break;
            case "home":
                setShowHome(true);
                setShowSettings(false);
                setShowSparkshelf(false);
            break;
            case "sparkshelf":
                setShowHome(false);
                setShowSettings(false);
                setShowSparkshelf(true);
            break;
            default:
                setShowHome(true);
                setShowSettings(true);
                setShowSparkshelf(true);
            break;
        }
    }

    const handleMessageFromChild = (json) => {
        console.log(json);
        
         if (json.actionType === "loadContent") {
             onMessageToParentFromComponent(json);
         } 
        // logInformation(json.actionType, 100000);
        // logInformation("From Profile Index", 100000);
    };



  return (
    <div className={styles.ProfileContainer}>
        <ProfileHeader handleMessageFromChild={handleHeaderCallback} />
        <ProfileButtons handleMessageFromChild={handleHeaderCallback} />
      
    
      {showHome && <div><StudentHome handleProjectClick={handleProjectClick} onMessageToParentFromComponent={onMessageToParentFromComponent}  /></div>}
      {showSparkshelf && <div><Sparkshelf OnMessageToParentFromChild={handleMessageFromChild} /></div>}
      {showSettings && <div><StudentSettings onLogout={handleLogout} /></div>}
    </div>
  );
};

export default StudentProfile;
