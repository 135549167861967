import React from 'react';
import styles from './index.module.css';

function ProjectProgressBar({ percentage }) {
  return (
    <div className="progress-bar-container">
       <div>  Project Progress: {percentage}%</div>
      <div
        className={styles.ProgressBar}
        style={{
          backgroundImage: `linear-gradient(to right, #28d757 ${percentage}%, transparent ${percentage}%)`
        }}
      />
    
     
    </div>
  );
}

export default ProjectProgressBar;
