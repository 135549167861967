import React, { useState, useRef, useEffect } from 'react';

const SparkerVideo = ({ OnMessageToParentFromComponent, ContentItemId }) => {
  const videoRef = useRef(null);
  const [showCompleteButton, setShowCompleteButton] = useState(false);

  useEffect(() => {
    handleStartVideo(); // Automatically start the video when the component mounts
    
  }, []);

  const handleStartVideo = () => {
    setShowCompleteButton(false);
    videoRef.current.play();
  };

  const handleVideoProgress = () => {
    const video = videoRef.current;
    if (video.currentTime >= 0.9 * video.duration) {
      setShowCompleteButton(true);
    }
  };

  const handleCompleteSpark = () => {
    OnMessageToParentFromComponent();
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: 'auto', overflow: 'hidden' }}>
      <div style={{ position: 'absolute', top: '10px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}>
        {showCompleteButton && (
          <button onClick={handleCompleteSpark}>Complete Spark</button>
        )}
      </div>
      <video
        ref={videoRef}
        onTimeUpdate={handleVideoProgress}
        controls
        width="600"
        height="400"
        style={{ display: 'block', maxWidth: '100%', maxHeight: '100%' }}
      >
        <source
          src="https://sparkerbucket.s3.amazonaws.com/SparkVideo1.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default SparkerVideo;
