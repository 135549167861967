import React, { useState, useEffect, act } from 'react';
import { BASE_URL, API_GET_QUIZ_QUESTIONS } from '../../../Shared/Config'; 
import QuestionComponent from './QuestionComponent';
import { useData } from '../../../Shared/Context';
import axios from 'axios'; // Import Axios for making HTTP requests

function QuizComponent({OnMessageToParentFromComponent, contentDetails}) {
  const [questions, setQuestions] = useState([]);
  const [contentId, setContentId] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const { activeStudent } = useData();


  const fetchQuiz = async (_id) => {
    try {
      const url = `${BASE_URL}${API_GET_QUIZ_QUESTIONS}?quiz_id=${_id}`;
      const response = await fetch(url);
      const data = await response.json();
      setQuestions(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };





  useEffect(() => {
    const fetchData = async () => {    
      
      try {
        const _id = contentDetails.id;
        setContentId(_id);
        const url = BASE_URL + 'sparker8-get-content-item?content_id=' + _id;
        console.log(url);
        const response = await fetch(url);
        const data = await response.json();
        const quizId = data[0].Content[0].description;
        fetchQuiz(quizId);
        console.log();
      
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [contentDetails]);


  const closeContent = () => {
    const sentInfo = {
      actionType: "closeContent",
      id: {contentId}
    };
    OnMessageToParentFromComponent(sentInfo);
  };

  const saveData = async () => {
    try {
      const url=BASE_URL + 'sparker8-complete-student-assigned-content?content_id=' + contentId + '&student_id=' + activeStudent.StudentId;
      //console.log(url);
      
      const response = await axios.post(url);
      closeContent();
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const goToNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      //alert("Good job, you are done!");
      saveData();
    }
  };

  const goToPreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  if (questions.length === 0) {
    return <div>Loading...</div>;
  }

  const currentQuestion = questions[currentQuestionIndex];
  const isFirstQuestion = currentQuestionIndex === 0;

  return (
    <div>
      <QuestionComponent 
        key={currentQuestionIndex}
        questionId={currentQuestion.QuestionId} 
        onNext={goToNextQuestion}
      />
      {!isFirstQuestion && (
        <div>
         
        </div>
      )}
      <div>
        Question {currentQuestionIndex + 1} of {questions.length}
      </div>
    </div>
  );
}

export default QuizComponent;