import React from 'react';
import './Home.css'; // Import your home page styles
import './Invite3.css'; // Import your invite styles

const HomeGeneric = () => {
  // Function to set the IsSparker cookie
  const setSparkerCookie = () => {
    document.cookie = "IsSparker=true; path=/; max-age=31536000"; // Set cookie for 1 year
    window.location.reload(); // Reload the page to apply the change
  };

  return (
    <div className="main-container">
      <div className="header-image-container"></div>

      <div className="header-image-container">
        <img src="/images/Logo4c_small.png" alt="Avatar" className="invite-logo" />
      </div>
      <br />
      <hr />
      <br />
      <div className="standard-text-container">
        <b onClick={setSparkerCookie} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
          Click here if you are a current Sparker student looking to login
        </b>
      </div>
      <br />
      <hr />
      <br />

      <div className="standard-text-container">
        <p>
          Sparkers Club is currently in its pilot phase and is not open to public enrollment. We are working closely
          with a select group of students to refine our programs before officially launching.
        </p>
        <p>
          We are a <span className="highlight-orange">non-profit organization dedicated to inspiring young innovators through project-based learning</span>,
          fostering creativity, sustainability, and a passion for making things.
        </p>
      </div>
      <h3>What we are aiming for:</h3>
      <div className="standard-text-container">
        <ul>
          <li>
            <span className="highlight-orange">Engaging Maker Curriculum for Ages 8-18:</span> Our curriculum is designed to foster creativity, critical
            thinking, and problem-solving skills through a variety of hands-on projects and activities.
          </li>
          <li>
            <span className="highlight-orange">Regular Meetings in Cleveland/Gaston County:</span> Students will attend interactive sessions where young
            minds come together to explore, create, and collaborate in a supportive environment.
          </li>
          <li>
            <span className="highlight-orange">Online Resources and Support:</span> Students in our organization will have access to exclusive online
            resources, tutorials, and support materials to further enhance your learning journey outside of our regular
            meetings.
          </li>
        </ul>
      </div>
      <h3>Stay Updated:</h3>
      <div className="standard-text-container">
        <p>We're excited to share our progress with you as we prepare to open our doors to the wider community. Keep an eye on our website for updates!</p>
      </div>

      <div className="header-image-container">
        <img src="/images/pilot2.png" alt="Real-world Interaction" className="header-image" />
      </div>
    </div>
  );
};

export default HomeGeneric;
