import React, { useEffect } from 'react';

const TutorialStep3 = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
      }, []);
  return (
    <div className="content-container htmlItem">
      <h2 className="article-title-container">Sparker Ranks: Unlock Your Inner Awesome!</h2>
      <p>Just like your favorite video game, the Sparkers Club has different levels you can conquer! Each level unlocks new skills, tougher challenges (but way cooler projects too!), and awesome badges to show off your progress.</p>
      {/* <p>Here's the breakdown of the ranks:</p> */}
      
      {/* <table>
        <thead>
            <tr>
                <th>Rank</th>
                <th>Description</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Sparker Junior</td>
                <td>That's you right now! You're just starting out, learning the basics and getting ready for an amazing journey.</td>
            </tr>
            <tr>
                <td>Sparker Apprentice</td>
                <td>As you gain more knowledge and complete your first projects, you'll move up to this rank. You'll be learning more advanced skills and starting to create even cooler stuff.</td>
            </tr>
            <tr>
                <td>Sparker Novice</td>
                <td>You're getting the hang of things now! As a Novice, you'll tackle bigger challenges and start to see your creations really come to life.</td>
            </tr>
            <tr>
                <td>Sparker Guru</td>
                <td>Look at you go! As a Guru, you're becoming a real pro. Your projects are more complex, and you're mastering the skills you need to make amazing things.</td>
            </tr>
            <tr>
                <td>Sparker Ninja</td>
                <td>You've made it to the top! As a Ninja, you can come up with your own ideas and bring them to life. Your imagination is your only limit, and you have all the skills you need to create anything you can dream up!</td>
            </tr>
        </tbody>
      </table> */}
      
      <p><span className="article-special-text">For each rank you advance, you will be given a unique badge to represent your success - and be able to participate in cooler projects!</span></p>
      <p>So, get ready to dive into a world of creativity and fun as we embark on this adventure together! Each step you take brings you closer to becoming a Sparker Master, where you can create anything your imagination can come up with. Let's get started and see what amazing things we can build together! 🚀🎳✨</p>
      <p>How do I learn all this cool stuff and advance through the ranks, you ask? Keep reading to find out!</p>
    </div>
  );
};

export default TutorialStep3;
