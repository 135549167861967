// DataContext.js
import React, { createContext, useContext, useState } from 'react';


const DataContext = createContext();

export const DataProvider = ({ children }) => {


  const [credentialsGood, setCredentialsGood] = useState(false);
  const [activeStudent, setActiveStudent] = useState({});

  const contextValue = { 
    activeStudent,
    setActiveStudent,
    credentialsGood,
    setCredentialsGood
  };

  

  return (
    
    <DataContext.Provider value={contextValue}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => {
  return useContext(DataContext);
};
