import React, { useEffect, useState } from 'react';
import axios from 'axios'; // Import Axios for making HTTP requests
import { logMessage, logInformation } from '../../../Shared/Logging';
import ArticleBase from './ArticleBase';
import { useData } from '../../../Shared/Context';
import { BASE_URL } from '../../../Shared/Config';
import HTMLItem from '../HTMLItem';
import styles from '../index.module.css';


const Article = ({ OnMessageToParentFromComponent, contentDetails }) => {
  const [showArticle, setShowArticle] = useState(false);
  const [allPages, setAllPages] = useState([]);
  const [contentId, setContentId] = useState(0);
  const { activeStudent } = useData();
  const [isInitialView, setIsInitialView] = useState(true);
  const [title, setTitle] = useState("");


  useEffect(() => {
    setContentId(contentDetails.id);
    fetchData(contentDetails.id);
  }, [contentDetails.id]);

  const fetchData = async (_id) => {
    try {
      
      const response = await axios.get(BASE_URL + 'sparker8-get-content-item?content_id=' + _id);
      
      setTitle(response.data[0].Title)
      distributeContent(response.data[0]);
      if (contentDetails.origin==='bookshelf')
      {setIsInitialView(false); //Don't show the complete button
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const saveData = async (content_id, student_id) => {
    try {
      const url=BASE_URL + 'sparker8-complete-student-assigned-content?content_id=' + content_id + '&student_id=' + student_id;
      //console.log(url);
      const response = await axios.post(url);
      closeContent();
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const distributeContent = async (_data) => {
    //console.log("!!!");
    //console.log(_data);
    let paragraphs = [];
    let sentences = [];
    let pages = [];

    _data.Content.forEach((item) => {
      if (item.TextContent === "NP") {
        //A new paragraph is needed
        if (sentences.length > 0) {
          //If there are any sentences...add them to the paragraph array and reset the sentences array
          paragraphs.push(sentences);
          sentences = [];
        }
      } else if (item.TextContent === "pagebreak") {
        //A page break means the page is over.
       
        if (sentences.length > 0) {
            //Load any left over sentences in the paragraph array
          paragraphs.push(sentences);

          //Reset the sentences array
          sentences = [];
        }
        if (paragraphs.length > 0) {
          //Load the paragraphs in to the pages array
          pages.push(paragraphs);



          //reset the paragraphs array
          paragraphs = [];
        }
      } else if (item.TextContent === "htmlItem"){
        //Same as page break...then create a new element to push
          if (sentences.length > 0) {
            //Load any left over sentences in the paragraph array
            paragraphs.push(sentences);

            //Reset the sentences array
            sentences = [];
          }
          if (paragraphs.length > 0) {
            //Load the paragraphs in to the pages array
            pages.push(paragraphs);

            //reset the paragraphs array
            paragraphs = [];
          }

          pages.push(item);
          
      }      
      else {
        //It is not a new paragrap or a new page
        //is it a picture??
        sentences.push(item);
      }
    });

    if (sentences.length > 0) {
      paragraphs.push(sentences);
    }
    if (paragraphs.length > 0) {
      pages.push(paragraphs);
    }

    setAllPages(pages);
    setShowArticle(true); // Set to true after setting allPages
  };

  const closeContent = () => {
    const sentInfo = {
      actionType: "closeContent",
      id: {contentId}
    };
    OnMessageToParentFromComponent(sentInfo);
  };


  const handleMessageFromChild = (json) => {
    //console.log((json.id.contentId));
    saveData(json.id.contentId,activeStudent.StudentId);

  }

  

  

  return (
    <div className={styles.ArticleContainer}>
      {showArticle && (
        <div>
          <div className="article-title-container">
          {title}
            </div>
       
          <ArticleBase OnMessageToParentFromComponent={handleMessageFromChild} isInitialView={isInitialView}  baseData={allPages} contentId={contentId} />
        </div>
      )}
      
    </div>
  );
};

export default Article;

