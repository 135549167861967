import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LoginRoute = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Set the IsSparker cookie to true
        document.cookie = `IsSparker=true; path=/; SameSite=Strict; Secure`;

        // Parse and log cookies (optional for debugging)
        const cookies = document.cookie.split(';').reduce((acc, cookie) => {
            const [key, value] = cookie.split('=').map((part) => part.trim());
            acc[key] = value;
            return acc;
        }, {});
        console.log('Current cookies:', cookies);

        // Redirect back to the main component
        navigate('/');
    }, [navigate]);

    return <div>Redirecting...</div>;
};

export default LoginRoute;
