import React from 'react';
import NextContent from '../NextContent';
import StudentProjects from '../StudentProjects';
import styles from './index.module.css';

const StudentHome = (props) => {
  const { onMessageToParentFromComponent, onLogout, loadScreen, handleProjectClick } = props;
  
  const handleFinalLoad = (json) => {
    console.log(json);
    onMessageToParentFromComponent(json);
}


  return (
    <div className={styles.componentContainer}>
     
      <NextContent loadFinal={handleFinalLoad} />
      <br />
      
      <StudentProjects handleProjectClick={handleProjectClick} />

    </div>
  );
};

export default StudentHome;
