import React, { useEffect, useState } from 'react';
import SparkerVideo from './SparkerVideo';
import { BASE_URL } from '../../../Shared/Config';
import axios from 'axios'; // Import Axios for making HTTP requests

const Video = ({ OnMessageToParentFromComponent, contentDetails }) => {
    const [showVideo, setShowVideo] = useState(false);
    const [showWelcome, setShowWelcome] = useState(true);
    const [showDone, setShowDone] = useState(false);

    useEffect(() => {
        console.log("A video has been opened and the id is " + JSON.stringify(contentDetails));
        fetchData(contentDetails.id);
    }, []); // Empty dependency array ensures this effect runs only once on mount

    const fetchData = async (_id) => {
        try {
          
          const response = await axios.get(BASE_URL + 'sparker8-get-content-item?content_id=' + _id);
          //console.log(response.data[0]);
          //distributeContent(response.data[0]);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };


    const handleMessageFromChild = (json) => {
        console.log(json);
    }

    const closeContent = () => {
        const sentInfo = {
          actionType: "closeContent",
          id: contentDetails.id
        };
        OnMessageToParentFromComponent(sentInfo);
    };

    const handleStartButtonClick = () => {
        setShowWelcome(false);
        setShowVideo(true);
    };

    return (
        <div>
          
            {showWelcome && (
                <div>
                    <p>Looks like you got yourself assigned a video spark! How cool. Only go in to this spark when you are ready to watch (and listen) to a video...</p>
                    <button onClick={handleStartButtonClick}>Start Watching</button>
                </div>
            )}
            {showVideo && <div><SparkerVideo ContentItemId={contentDetails.id} OnMessageToParentFromComponent={handleMessageFromChild}/></div>}
            {showDone && <div></div>}
        </div>
    );
}

export default Video;
