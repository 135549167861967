import React, { useState, useEffect } from 'react';
import Login from '../Login';
import { useData } from '../../Shared/Context';
import { BASE_URL, API_ENDPOINT_GET_STUDENT } from '../../Shared/Config';
import { logMessage } from '../../Shared/Logging';
import { logInformation } from '../../Shared/Logging';
import { logActivity } from '../../Shared/activityLogger';
import axios from 'axios'; // Import Axios for making HTTP requests



function CredentialsManager() {

    const [shownMessage, setShownMessage] = useState("I am the credentials component.");
    const { setCredentialsGood, activeStudent, setActiveStudent } = useData();
    const [localStorageChecked, setLocalStorageChecked] = useState(false);
    const [showLoginNeeded, setShowLoginNeeded] = useState(false);
    const [showLogOutNeeded, setShowLogOutNeeded] = useState(false);
    const [accountLoadedToContext, setAccountLoadedToContext] = useState(false);


    //Entry point. Begin checking credentials.
    const checkCredentialsBegin = async () => {
        try {
            logMessage("checkCredentialsBegin", 10);

            checkContextAccount();

        } catch (error) {
            console.error('Error checking credentials:', error);
        }
    }


   
    ///Check to see if there is an account already in the context.
    const checkContextAccount = () => {
       
        let active = false;

        try{
            if ((Object.keys(activeStudent).length !== 0) || (activeStudent===null)) {
                active=true;
              
                setCredentialsGood(true); //let the system know we are good to go.
                //checkForLocalAccountId
                setShownMessage("There is an active account in the context. We are good to go.");
                logMessage("There is an active account in the context. We are good to go.");
            }
            else{
                logMessage("There is NOT an active account in the context. Is there an account ID stored locally?");
                setShownMessage("No account in context. Checking for cookies...");
                checkForLocalAccountId();
            }
        }
        catch(err){
            logMessage("Error in checkContextAccount");
          checkForLocalAccountId();
        }

    }

    ///Fetch the data about a single student using the ID
    const fetchUserData = async (student_id) => {
        try {
          const response = await axios.get(`${BASE_URL}${API_ENDPOINT_GET_STUDENT}?student_id=${student_id}`);
          return response.data; // Axios automatically parses JSON for you
        } catch (error) {
          throw new Error('Error fetching data:', error); // Throw the error for proper handling
        }
      };
      

      //Load the data in to the context
      const loadUserDataToContext = async (_id) => {
        try {
         
          logMessage("loadUserDataToContext");
          
          //Load the user data from the database
          const userData = await fetchUserData(_id);
          
          logMessage("Loading " + JSON.stringify(userData) + " into context.");
      
          //Store the user's ID in local storage.
          localStorage.setItem("nsf.deviceUserId", _id.toString());
          logMessage("%%%" + JSON.stringify(userData[0]));
          //Save the user's data in the context
          setActiveStudent(userData[0]);
         

          //Set the account loaded in to context flag as true
          //This will trigger further actions
          setAccountLoadedToContext(true);
      
        } catch (error) {
          // Handle errors if any
          console.error("Error:", error.message);
          // Optionally, rethrow the error to propagate it further
          throw error;
        }
      };
      

    const checkForLocalAccountId = async () => {
        logMessage("checkForLocalAccountId"); 
        let localUserId = localStorage.getItem("nsf.deviceUserId");
        
      
        if (localUserId != null){
           
            setShownMessage("We have found a user...");
            setLocalStorageChecked(true);
            loadUserDataToContext(localUserId);
            ////localStorage.removeItem("nsf.deviceUserId");
        }
        else{
            setShownMessage("");
            setLocalStorageChecked(true);
            setShowLoginNeeded(true);
            setShowLogOutNeeded(false);
          }
    }

    //Run everytime the component is loaded.
    useEffect(() => {
        logInformation("CredentialsManager.useEffect...");
        checkCredentialsBegin();
      }, []);


      //run when account loaded in to context...
      useEffect(() => {
        if (accountLoadedToContext){
          setShowLoginNeeded(false);
          setShowLogOutNeeded(true);
          setShownMessage("Logged In As " + activeStudent.FirstName);
        
          logMessage(activeStudent.FirstName)
          setCredentialsGood(true);
          logMessage("HEY NOW. WE HAVE AN ACCOUNT. WE ARE GOOD RIGHT?!?");
        }

      }, [accountLoadedToContext]);



      //Decide what to do when a child component sends back data
      const handleMessageFromChild = (json) => {
        let obj = JSON.parse(json);
        if (obj.actionType=="loginSuccess"){
            //checkLogin();
            loadUserDataToContext(obj.id.toString());

        }
        else if (obj.actionType=="loginSuccess"){
          
        }
        
      };



  return (
    <div className="container">
    {shownMessage}
      {showLoginNeeded && 
        <Login OnMessageToParentFromLogin={handleMessageFromChild}/>

        }


  

    </div>
  );
}

export default CredentialsManager;
