const logging_enabled = true;
const logging_level = 0;

export const logMessage = (message, type="information") => {

    if (logging_enabled){
        if (type=="error"){
            //console.error(message);
        }
        else if (type=="important"){
            console.log(message);
        }
        else{
            //console.log(message);
        }
        
    }
    
  };

  export const logInformation = (message, level=1) => {

    if (logging_enabled){
        if (level==1000){
            console.error(message);
        }
        else if (level>logging_level){
            console.log(message);
        }
        else{
            //console.log(message);
        }
        
    }
    
  };