import React from 'react';
import ProjectProgressBar from './ProjectProgressBar';
import styles from './index.module.css';

const ProjectItem = ({ projectTitle, projectDescription, projectCompletion, onProjectClick, projectId }) => {
  const handleClick = () => {
    const sentInfo = {
      actionType: "loadProject",
      projectId: projectId,
      contentType: "project",
      projectTitle: projectTitle,
      projectDescription: projectDescription
    };

    console.log(sentInfo);
    onProjectClick(sentInfo);
  };

  return (
    <div className="main-button main-button-green" onClick={handleClick}>
      <div className='main-button-large-text'>
       Project: {projectTitle}
      </div>
    </div>
  );
};

export default ProjectItem;
