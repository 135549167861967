import React, { useState, useEffect } from 'react';
import RandomWordGenerator from './RandomWordGenerator';
import PreviousNextButtons from '../../Shared/PreviousNextButtons';

import { BASE_URL } from '../../Shared/Config';

const Onboarding = ({ InviteCode }) => {
    const [showWelcome, setShowWelcome] = useState(true); // Set to true initially
    const [showName, setShowName] = useState(false);
    const [nickName, setNickName] = useState("");   
    const [submissionSuccess, setSubmissionSuccess] = useState(false);
    const [inviteNotFound, setInviteNotFound] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [showFinalStep, setShowFinalStep] = useState(false);
    const [showMoreInfo, setShowMoreInfo] = useState(false);



    const [secretCode, setSecretCode] = useState("");
    const [showSecretCode, setShowSecretCode] = useState(false);


    const [selectedAvatarFilename, setSelectedAvatarFilename] = useState(null);
    const [avatarSelected, setAvatarSelected] = useState(false);
    
    const [showAvatars, setShowAvatars] = useState(false);

    useEffect(() => {
        // Fetch invite data on component load
        fetch(BASE_URL + `sparker8-get-invite?invite_code=${InviteCode}`)
            .then(response => response.json())
            .then(data => {
                if (data.length > 0) {
                    // If invite is found, set nickname
                    setNickName(data[0].FirstName);
                    setShowWelcome(true); // Show welcome message only if invite is found
                    setShowName(false); // Show nickname input immediately
                    setShowSecretCode(false);
                    setShowFinalStep(false);
                    setShowMoreInfo(false);
                } else {
                    // If invite is not found, display message
                    setInviteNotFound(true);
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, [InviteCode]);

    const handleGetStarted = () => {
        setShowWelcome(false);
        setShowName(true);
        setShowSecretCode(false);
        setCurrentPage(2);
        setShowFinalStep(false);
        setShowMoreInfo(false);
    };

    const handlePrevNextClick = (buttonType) => {     
        if (currentPage==2){
            handleNameSubmit(buttonType)
        }
        else if (currentPage===3){
            handleSecretWordSubmit(buttonType);
        }
        else if (currentPage===4){
            handleFinalStepSubmit(buttonType);
        }
        

        if (buttonType === 'previous') {  
          //handleMessageFromChild(previousValue);
        } else if (buttonType === 'next') {
          //handleMessageFromChild(nextValue);
        }
      };

      
      const handleFinalStepSubmit = (buttonType) => {
        
        setShowFinalStep(false);
        setShowSecretCode(false);
        setShowAvatars(false);
        setShowMoreInfo(true);
        setSubmissionSuccess(false);
        setCurrentPage(5);
      }

      const handleSecretWordSubmit = (buttonType) => {
        if (buttonType==='previous'){
            setCurrentPage(2);
            setShowName(true);
            setShowSecretCode(false);            
            setShowWelcome(false);
            setShowFinalStep(false);
            setShowSecretCode(false);
            setShowMoreInfo(false);
        }
        else{
            setCurrentPage(4);
            handleFinish();
            setShowName(false);
            setShowSecretCode(false);            
            setShowWelcome(false);
            setShowFinalStep(true);
            setShowMoreInfo(false);

        }
      }
    const handleNameSubmit = (buttonType) => {
        if (buttonType==='previous'){
            setShowName(false);
            setShowSecretCode(false);
            setCurrentPage(1);
            setShowWelcome(true);
            setShowMoreInfo(false);
        }
        else{
            if (nickName.trim() !== "") {
                setShowName(false);
                setShowSecretCode(true);
                setCurrentPage(3);
                setShowMoreInfo(false);
            }
        }       
        
    };



    const handleAvatarSelect = (filename) => {
      
        setSelectedAvatarFilename(filename);
        setAvatarSelected(true);
    };

  const onWordGenerated = (word) => {
    setSecretCode(word);
  }

    const handleFinish = () => {
        // Construct the URL with the required data
        const url = BASE_URL + `sparker8-post-new-student?nick_name=${nickName}&invite_code=${InviteCode}&secret_code=${secretCode}`;

        // Make a POST request to the endpoint
        fetch(url, {
            method: 'POST',
        })
            .then(response => {
                // Handle the response as needed
                console.log("Request sent successfully");
                // Update state to indicate successful submission
                setSubmissionSuccess(true);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };



    return (
        <div className="onboarding-container-sparkers content-container htmlItem">
          {showMoreInfo && (
    <div>
        More information you want?!
    </div>
)}
            {/* Show invite not found message */}
            {inviteNotFound && (
                <div>
                    <h2>Sorry, invalid code</h2>
                </div>
            )}

            {/* Show the "You are done now" message if submission is successful */}
            {submissionSuccess && (
                <div>
                  <h2>That is it! You are done. Your student is ready to begin!</h2>
                             <p>Just remember, to login your student needs to know:</p>
                            <p>The URL: <b>http://sparkers.app</b></p>
                            <p>Their preferred name: <b>{nickName}</b></p>
                            <p>Their secret code: <b>{secretCode}</b></p>
                            <p>You can close this app now. However, if you would like to learn more about our program and process, feel free to proceed below!</p>
                            <PreviousNextButtons onClick={handlePrevNextClick} previousVisible={false} />
                </div>
            )}

            {/* Show the onboarding steps if submission is not yet successful */}
            {!submissionSuccess && !inviteNotFound && (
                <>
                    {showWelcome && (
                        <div>
                            <img src="/images/Logo4c_small.png" alt="Avatar" className='onboarding-container-sparkers-logo' />
                            <p>Hello Sparker Guardian! 🎉 Fantastic news! Your student has been selected to join the Sparkers club! How exciting is that?</p>

                            <p>Our goal is to inspire young innovators through hands-on project-based learning, nurturing creativity, sustainability, and a passion for making things. </p>
                            <p>We're excited to welcome your student into our community of young creators and visionaries.</p>
                            <p>If you are ready to get them started, proceed below!</p>
                           
                            <button className="button4 next-button4" onClick={handleGetStarted} >
                            &nbsp;
                            </button>

                            
      
                        </div>
                    )}

                    {showName && (
                        <div>
                            <h2>First, tell us what your child wants to be called!</h2>
                            <input
                                type="text"
                                placeholder="Nickname"
                                value={nickName}
                                onChange={(e) => setNickName(e.target.value)}
                                className='login-name-input'
                            />

                            <PreviousNextButtons onClick={handlePrevNextClick} />

                           
                          
                        </div>
                    )}



                    {showSecretCode && (
                        <div>
                            <p>Awesome!</p>
                            <p>We will never tell {nickName} to keep a secret...except for this one thing:</p>
                            <RandomWordGenerator onWordGenerated={onWordGenerated} />
                            <p>We need them to remember this word to log in to the app. But we don't want ANY other student to know what their word is. </p><p>It is like a password...but a little more simple. So write it down...or save it...or just store it in your noggin. Just please make sure your student knows it to login!</p>
                            <PreviousNextButtons onClick={handlePrevNextClick} />

                        </div>
                    )}

                    {showFinalStep && (
                        <div>
                           Saving...
                        </div>
                    )}

                   
                </>
            )}
        </div>
    );
};

export default Onboarding;
