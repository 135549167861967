import React, { useEffect, useState } from 'react';
import '../../../../../../Shared/styles.css';
import { BASE_URL } from '../../../../../../Shared/Config';
import CodeSample from '../CodeSample';
import styles from '../../../../index.module.css';


const ArticleParagraph = ({ sentences }) => {
  const [allSentences, setAllSentences] = useState([]);
  const [formattedSentences, setFormattedSentences] = useState([]);
  const [showData, setShowData] = useState(false);
  const [myElement, setMyElement] = useState('');
  const [spanElements, setSpanElements] = useState([]);

  const distributeContent = async (data) => {
    let _all = [];

    data.forEach(item => {
      _all.push(item);     
    });
    setAllSentences(_all);
  }


  function nameOfClass(theType){
    switch (theType.toLowerCase()) {
      case 'special':
      case 'Special':
        return 'article-special-text';
      case 'title1':
        return 'article-title1-text';
      case 'title2':
        return 'article-title2-text';
      default:
        return 'article-standard-text';
    }    
  };


  const formatContent = async () => {
    
    let _spans = [];

    allSentences.forEach(item => {
      //console.log("Item: ");
      //console.log(item);
      
      if (item.TextContent==='image1'){
      let pictureElement = (
              <img
              src={`/images/article_pictures/${item.description}`}
                alt="Article Picture"
                className="article_picture_standard"
              />
            );

            _spans.push(pictureElement);
      }
      else if (item.TextContent==='table1'){
        let articleElement = (
         <CodeSample tableData={item.description} />
        );

        _spans.push(articleElement);
      }
      else{
        let classy=nameOfClass(item.description);

        let sentenceElement = <span className={classy}>{item.TextContent}</span>
        _spans.push(sentenceElement);
      }
      

     
      
    });
    setFormattedSentences(_spans);
  }

  useEffect(() => {
    //console.log("Current Page Data: ", JSON.stringify(sentences));
    
      formatContent();
   
    

  }, [allSentences]);


  useEffect(() => {
   
    if (allSentences != null){      
      setShowData(true);
    }
    

  }, [formattedSentences]);


  useEffect(() => {
    //console.log("Current Page Data: ", JSON.stringify(sentences));
    distributeContent(sentences)

  }, []);

  return (
    <div>

{showData && (
  <div className={styles.ArticleParagraphContainer}>
    {formattedSentences.map((span, index) => (
            <React.Fragment key={index}>
              {span}
            </React.Fragment>
          ))}


  </div>
)}
      
    </div>
  );
};

export default ArticleParagraph;


