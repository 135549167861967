import React, { useEffect, useState } from 'react';
import axios from 'axios'; // Import Axios for making HTTP requests
import { BASE_URL, API_ENDPOINT_GET_STUDENT_BOOKSHELF } from '../../../Shared/Config'; 
import { useData } from '../../../Shared/Context';
import { logInformation } from '../../../Shared/Logging';
import styles from './index.module.css';
import StudentProjects from '../StudentProjects';

const Sparkshelf = ({OnMessageToParentFromChild}) => {
  const { activeStudent } = useData();
  const [pulledData, setPulledData] = useState([]); // State variable to store fetched data

  useEffect(() => {
    fetchData();
  }, []);

  const handleProjectClick = () => {
    alert("Clicked");
  }
  const fetchData = async () => {
    try {
      let url = `${BASE_URL}${API_ENDPOINT_GET_STUDENT_BOOKSHELF}?student_id=${activeStudent.StudentId}`;

      logInformation("Loading URL " + url, 1);

      const response = await fetch(url);

      if (!response.ok) {
        logInformation("Failed to fetch data", 1);
        throw new Error('Failed to fetch data');
      }

      const data = await response.json();
      setPulledData(data); // Update the state with fetched data
      logInformation("setPulledData", 1);
    } catch (error) {
      logInformation('Error fetching data: ' + error, 1);
      console.error('Error fetching data:', error);
    }
  };

  const handleItemClick = (contentId) => {
    const sentInfo = {
      actionType: "loadContent",
      id: contentId,
      contentType: 1,
      origin: "bookshelf"
    };
    OnMessageToParentFromChild(sentInfo);
  };

  return (
    <div className={styles.BookshelfContainer}>
      <h2>{activeStudent.FirstName}'s Sparkshelf</h2>
      <div className={styles.BookshelfContent}>
       
        {pulledData.length > 0 ? (
          pulledData
            .filter((item) => item.ContentType !== 3) // Exclude items with ContentType = 3 (quizes)
            .map((item, index) => (
              <div key={index} className={styles.BookshelfItem} onClick={() => handleItemClick(item.ContentId)}>
                <img className={styles.ContentItemIcon} src={item.Logo ? item.Logo : 'images/book2small.png'} alt="Content" />
                <p className={styles.ContentTitle}>{item.Title}</p>
              </div>
            ))
        ) : (
          <p>Nothing here yet...get to reading!</p> // Optionally show a loading message while data is being fetched
        )}
      </div>      
      <div>
        <StudentProjects handleProjectClick={handleProjectClick} showAllProjects={true} />
      </div>
    </div>
  );
  
};

export default Sparkshelf;
