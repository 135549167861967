import React, { useEffect } from 'react';

const TutorialStep5 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <div className="content-container htmlItem">
      <h2 className="article-title-container">How will I Know?!</h2>
      <p>Easy! You see that gray lightning bolt below?</p>
      <div className="green-lightning-container">
      <img src="/images/nextcontentlogo_gray.png" alt="Gray Lightning Bolt"  />
      </div>
      <p><span className="article-special-text">Anytime a new spark is available for you to consume, it will show up as a big green lightening bolt </span>on this home screen.</p>

      <p>When there is not a spark for you to consume, it will be gray...like above.</p>
      <p>Want to see?? Go ahead...click next! We will assign you a spark to consume!</p>

    </div>
  );
};

export default TutorialStep5;
