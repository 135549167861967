import React, { useEffect } from 'react';
import NextContent from '../StudentProfile/NextContent';
import { useData } from '../../Shared/Context';

const TutorialStep7 = ({ onMessageToParentFromComponent }) => {
  const { activeStudent } = useData();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
 

  return (
    <div className="content-container htmlItem">
   
          <p>Good job! You completed your first spark!!</p>
          <p>Now, let's complete the tutorial by having you log out and log back in.</p>

          <p className="article-special-text">See that nice beautiful logo at the top left?? Click it and log out!</p>
          <p>But first! Don't forget your login information:</p>
          <p>Name: <b>{activeStudent.FirstName}</b><br />Secret Code:  <b>{activeStudent.LoginCode}</b></p>
          <p></p>
        </div>
  );
};

export default TutorialStep7;
