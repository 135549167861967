import React, { useEffect } from 'react';

const TutorialStep2 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <div className="content-container htmlItem">
      <h2 className="article-title-container">First and foremost</h2>
   
        <p>Our goal is for you to have a blast while discovering how straightforward it is to craft truly remarkable creations.</p>
        <p><span className="article-special-text">You are starting off as a Sparker Intern – a beginner in our club. 🌟</span></p>
        <p>Now, you might be wondering, what's an Intern? Well, think of it like this: <span className="article-special-text">an intern is someone who's brand new to something</span>, like when you're just starting to learn a new game or skill. But don't worry, everyone starts somewhere, and we're here to help you grow and spark your imagination. 🎮✨</p>
        <p>But here's the super cool part – <span className="article-special-text">as you learn and create, you'll level up through the ranks of the Sparkers Club</span>! It's like being in a game where you earn badges and unlock new levels as you get better and better. 🏅🚀</p>
        
    </div>
  );
};

export default TutorialStep2;
