
import React, { useEffect, useState } from 'react';
import styles from './index.module.css';

const ProfileButtons = (props) => {
    const { handleMessageFromChild } = props;
    const [homeActive, setHomeActive] = useState(true);
    const [bookshelfActive, setBookshelfActive] = useState(false);
    

    const handleClick = (imageKey) => {
      if (imageKey==='home'){
        setHomeActive(true);
        setBookshelfActive(false);
      }
      else{
        setBookshelfActive(true);
        setHomeActive(false);
      }

        handleMessageFromChild(imageKey);


        console.log(imageKey);

    }
  return (
    <div>
   
    <button className={!homeActive ? styles.NavButton : styles.NavButtonActive} onClick={() => handleClick('home')}>
        <div><img src="/images/home.png" alt="Home" style={{ width: '50px', padding: '10px' }} /></div>
    </button>
    <button className={!bookshelfActive ? styles.NavButton : styles.NavButtonActive} onClick={() => handleClick('sparkshelf')}>
        <div><img src="/images/sparkshelf5.png" alt="Home" style={{ width: '50px', padding: '10px' }} /></div>
    </button>
    </div>
  );
};

export default ProfileButtons;
