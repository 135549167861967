import React, { useEffect, useState } from 'react';
import axios from 'axios'; // Import Axios for making HTTP requests
import { logMessage, logInformation } from '../../Shared/Logging';
import Article from './Article'; // Import the Article component
import Video from './Video';
import QuizComponent from './QuizComponent';
import { BASE_URL } from '../../Shared/Config';
import styles from './index.module.css';

const ContentItem = ({ OnMessageToParentFromComponent, contentDeets} ) => {

    const [contentDetails, setContentDetails] = useState({});
    const [showArticle, setShowArticle] = useState(false);
    const [showVideo, setShowVideo] = useState(false);
    const [showQuiz, setShowQuiz] = useState(false);
    
    useEffect(() => {
        
        //fetchData();
        console.log("Content: " + JSON.stringify(contentDeets));
        setContentDetails(contentDeets);

      }, []);

      useEffect(() => {
    
       if (contentDetails != null){
        if (contentDetails.contentType==1){
            setShowArticle(true);
        }       
        else if (contentDetails.contentType===2){
          setShowVideo(true);
        }
        else if (contentDetails.contentType===3){
          setShowQuiz(true);
        }
       }
        
      }, [contentDetails]);

      const closeContent = () => {
       console.log("closeContent");
        const sentInfo = {
          actionType: "closeContent",
          id: 0
        };
        OnMessageToParentFromComponent(sentInfo);
      };

      const handleMessageFromChild = (json) => {
        console.log("handleMessageFromChild");
        if (json.actionType === "loadContent") {
          //setContentType(json.contentType);
          //setContentId(json.id);
          //there is a trigger for when this changes to actually show the needed stuff
        
        }
        else if (json.actionType === "closeContent"){        
          OnMessageToParentFromComponent(json);      
        }
        else{
          console.log("else....");
        }
  
      }

  return (
    <div className={styles.ContentContainer}>
      <div className="spark-close-without-saving-button-container">
        <button className="main-button main-button-green" onClick={closeContent}>Close Spark Without Saving</button>
        
      </div>
        
        {showArticle && <div><Article contentDetails={contentDetails} OnMessageToParentFromComponent={handleMessageFromChild}  /></div>}
        {showVideo && <div>
          <Video contentDetails={contentDetails} OnMessageToParentFromComponent={handleMessageFromChild}  />
          
          </div>}

          {showQuiz && <div><QuizComponent OnMessageToParentFromComponent={handleMessageFromChild} contentDetails={contentDetails} quizId="1"  /></div>}
          
    </div>
  );
}       

export default ContentItem;
