import React, { useEffect, useState } from 'react';
import ArticleParagraph from './ArticleParagraph';
import { BASE_URL } from '../../../../../Shared/Config';
import styles from '../../../index.module.css';


const ArticlePage = ({ currentPageDataObject, pageNumber }) => {
  useEffect(() => {
    //console.log("Paragraph Data: " + JSON.stringify(currentPageDataObject));
    //alert(currentPageDataObject[0][0].description);
  }, []); // Dependency array to ensure data updates are reflected


  useEffect(() => {
    //console.log("Current Page Data: ", currentPageDataObject);
  }, [currentPageDataObject]);

  return (
    <div className={styles.ArticlePageContainer}>
      <h3>Page {pageNumber}</h3>
      {currentPageDataObject.map((paragraph, index) => (
    
        <ArticleParagraph key={index} sentences={paragraph} />
        // <p key={index}>{paragraph[0].TextContent}</p>
      ))}
    </div>
  );
};

export default ArticlePage;
