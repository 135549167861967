import React, { useState } from 'react';
import { BASE_URL } from '../../../Shared/Config';
import { useData } from '../../../Shared/Context';

const StudentSettings = ({ onLogout }) => {
  const { activeStudent } = useData();
  const [showLoginCode, setShowLoginCode] = useState(false);

  const handleLogout = () => {
    const logoutData = {
      actionType: 'logout',
      id: 1 // Assuming the ID here, you can replace it with the actual ID
    };
    onLogout(logoutData);
  };

  const toggleLoginCodeVisibility = () => {
    setShowLoginCode((prev) => !prev);
  };

  return (
    <div>
      <p>Wait! Before you log out make sure you remember how to log back in!</p>
      <p>Go to <b>www.sparkers.org/login</b> and use the following information:</p>
      <div>
      <table className="student-info-table">
        <tbody>
          <tr>
            <td><b>Name:</b></td>
            <td>{activeStudent.FirstName}</td>
          </tr>
          <tr>
            <td><b>Secret Word:</b></td>
            <td>
              {showLoginCode ? activeStudent.LoginCode : '*****'}
              <button
                className="main-button main-button-link"
                onClick={toggleLoginCodeVisibility}
              >
                {showLoginCode ? 'Hide Secret Word' : 'Show Secret Word'}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
      <button className="main-button main-button-green" onClick={handleLogout}>
        Logout
      </button>
    </div>
  );
};

export default StudentSettings;
