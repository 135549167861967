import React, { useState, useEffect } from 'react';
import { BASE_URL, API_GET_QUESTION_OPTIONS, API_GET_QUIZ_QUESTION, API_GET_CANNED_TEXT, API_POST_ACTIVITY_LOG } from '../../../../Shared/Config';
import { useData } from '../../../../Shared/Context';
import { logActivity } from '../../../../Shared/activityLogger';

function QuestionComponent({ questionId, onNext }) {
  const [questionContent, setQuestionContent] = useState('');
  const [questionOptions, setQuestionOptions] = useState([]);
  const [incorrectResponse, setIncorrectResponse] = useState(null);
  const [correctResponse, setCorrectResponse] = useState(null);
  const [responseContent, setResponseContent] = useState('');
  const [showQuestion, setShowQuestion] = useState(true);
  const { activeStudent } = useData();

  const fetchQuestion = async () => {
    try {
      const url = `${BASE_URL}${API_GET_QUIZ_QUESTION}?question_id=${questionId}`;
      const response = await fetch(url);
      const data = await response.json();
      setQuestionContent(data.Content);
      setCorrectResponse(data.CorrectExplanationCannedTextId);
      setIncorrectResponse(data.IncorrectExplanationCannedTextId);
    } catch (error) {
      console.error('Error fetching question:', error);
    }
  };

  const fetchQuestionOptions = async () => {
    try {
      const url = `${BASE_URL}${API_GET_QUESTION_OPTIONS}?question_id=${questionId}`;
      const response = await fetch(url);
      const data = await response.json();
      setQuestionOptions(data);
    } catch (error) {
      console.error('Error fetching question options:', error);
    }
  };

  const fetchResponse = async (correct) => {
    try {
      let canned_text_id = incorrectResponse;
      if (correct){
        canned_text_id = correctResponse;
      }
      const url = `${BASE_URL}${API_GET_CANNED_TEXT}?canned_text_id=${canned_text_id}`;
      const response = await fetch(url);
      const data = await response.json();
      setResponseContent(data[0].Content);
      setShowQuestion(false);
    } catch (error) {
      console.error('Error fetching response:', error);
    }
  };


  function removeSingleQuotes(inputString) {
    return inputString.replace(/'/g, '');
  }

  useEffect(() => {
    const fetchData = async () => {
      await fetchQuestion();
      await fetchQuestionOptions();
    };
    
    fetchData();
  }, [questionId]);

  const handleOptionClick = (correct, optionContent) => {
    let message = (`User chose: ${optionContent} | Correct: ${correct}`);
    
    logActivity(activeStudent.StudentId, 3, removeSingleQuotes(message));
    fetchResponse(correct);
  };

  const handleNextClick = () => {
    onNext();
    setShowQuestion(true);
  };

  if (showQuestion) {
    return (
      <div className="question-container">
        <h2 className="question-title">Question</h2>
        <div dangerouslySetInnerHTML={{ __html: questionContent }} />
        
        <h3>Options</h3>
        <div className="options-container">
          
          {questionOptions.map((option) => (
            <div 
              key={option.QuestionOptionId} 
              onClick={() => handleOptionClick(option.Correct, option.Content)}
              className="main-button main-button-green"
            >
              <div dangerouslySetInnerHTML={{ __html: option.Content }} />
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div className="question-container">
        <div dangerouslySetInnerHTML={{ __html: responseContent }} />
        <button className="main-button main-button-green" onClick={handleNextClick}>Got it!</button>
      </div>
    );
  }
}

export default QuestionComponent;
