import React from 'react';

const PreviousNextButtons = ({ onClick, previousEnabled = true, nextEnabled = true, previousVisible = true, nextVisible = true }) => {
  const handlePreviousClick = () => {
    onClick('previous');
  };

  const handleNextClick = () => {
    onClick('next');
  };

  return (
    <div className="nav-buttons4">
      {previousVisible && (
        <button className="button4 previous-button4" onClick={handlePreviousClick} disabled={!previousEnabled}>
          &nbsp;
        </button>
      )}
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {nextVisible && (
        <button className="button4 next-button4" onClick={handleNextClick} disabled={!nextEnabled}>
          &nbsp;
        </button>
      )}
    </div>
  );
};

export default PreviousNextButtons;
