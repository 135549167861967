import React from 'react';

const SparkerContentConfirm = ({ imageUrl, text, handleContentClicked, onDoItNowClick, onDoItLaterClick }) => {

    
  const containerStyle = {
   
  };

  const backgroundImageStyle = {
 
  };

  const textStyle = {
    fontSize: '2em', // adjust font size as needed
    fontWeight: 'bold',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', // optional: text shadow for better visibility
   
    zIndex: 1, // ensure text is above the background image
  };

  const buttonStyle = {
    margin: '5px', // adjust button margin as needed
    padding: '10px', // adjust button padding as needed
    backgroundColor: 'blue', // fully opaque red background
    color: '#000000', // button text color
    border: 'none',
    borderRadius: '5px', // border radius for rounded corners
    cursor: 'pointer',
  };
  

  return (
    <div style={containerStyle}>
     
      
      <div style={textStyle}>
           {text}<br />
           <button className='main-button main-button-green' onClick={onDoItNowClick}>Consume Spark NOW</button>
   
           </div>
     
    </div>
  );
};

export default SparkerContentConfirm;
