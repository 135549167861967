import React, { useState, useEffect } from 'react';
import { BASE_URL, API_ENDPOINT_UPDATE_STUDENT_PROJECT_STEP, API_GET_CANNED_TEXT } from '../../../Shared/Config'; 
import { useData } from '../../../Shared/Context';

const ProjectStep = ({ step, onBack, onComplete }) => {
  const [cannedText, setCannedText] = useState('');
  const { activeStudent } = useData();

  useEffect(() => {
    console.log(step);
    fetch(`${BASE_URL}${API_GET_CANNED_TEXT}?canned_text_id=${step.CannedTextId}`)
      .then(response => response.json())
      .then(data => setCannedText(data[0].Content))
      .catch(error => console.error('Error fetching canned text:', error));
  }, [step.CannedTextId]);

  const handleGoBack = (completed = false) => {
    if (completed) {
      onBack(true);
    } else {
      onBack(false);
    }
  };

  const handleComplete = () => {
    
    const url = `${BASE_URL}${API_ENDPOINT_UPDATE_STUDENT_PROJECT_STEP}?student_id=${activeStudent.StudentId}&project_id=${step.ProjectId}`;

    console.log(url);
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.ok) {
          handleGoBack(true);
        } else {
          return response.text().then(text => { throw new Error(text); });
        }
      })
      .catch(error => console.error('Error updating project step:', error));
  };

  return (
    <div>
      
     
      <button className="main-button main-button-green" onClick={handleGoBack}>Back to Steps</button>

      <div dangerouslySetInnerHTML={{ __html: cannedText }} />
      <br />
      {step.StepStatus === 0 && (
        <button className="main-button main-button-green" onClick={handleComplete}>Mark Step Complete</button>
      )}
      <br /><br /><br /><br />
    </div>
  );
};

export default ProjectStep;
