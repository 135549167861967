import React, { useState, useEffect } from 'react';

const RandomWordGenerator = ({ onWordGenerated }) => {
  const words = [
    "Apple", "Ball", "Cat", "Dog", "Egg", "Fish", "Frog", "Hat", "Ice", "Jam",
    "Kite", "Lemon", "Moon", "Nest", "Owl", "Pig", "Queen", "Ring", "Sun", 
    "Tree", "Umb", "Vest", "Wink", "Xylo", "Yarn", "Zero", "Ant", "Bark", "Cake",
    "Duck", "Ear", "Fork", "Goat", "Hill", "Ink", "Jeep", "Key", "Log", "Milk",
    "Nail", "Oar", "Pink", "Quack", "Rose", "Sand", "Tent", "UFO", "Vase", "Wand",
    "Yawn", "Zebra", "Atom", "Bird", "Coal", "Drum", "Eel", "Fern", "Gate", "Hand",
    "Iris", "Joke", "Kelp", "Lamp", "Mop", "Nose", "Oboe", "Pail", "Quiz", "Rope",
    "Sock", "Tusk", "Vow", "Wand", "Xbox", "Yolk", "Zest", "Axe", "Bear", "Coin",
    "Dime", "Egg", "Fox", "Goat", "Hill", "Iron", "Jump", "Kite", "Lark", "Mint",
    "Nose", "Omen", "Puma", "Quok", "Rake", "Seal", "Tug", "Urn", "Veil", "Wisp",
    "Yule", "Bat", "Cup", "Den", "Fig", "Gap", "Hen", "Jug", "Lid", "Nut", "Pan",
    "Rat", "Saw", "Tap", "Van", "Web", "Yak", "Box", "Car", "Dot", "Elm", "Fan",
    "Gem", "Hug", "Jar", "Lip", "Mat", "Net", "Pen", "Ram", "Sin", "Tie", "Vat",
    "Wig"
];


  const [randomWord, setRandomWord] = useState('');

  useEffect(() => {
    // Generate a random word when component mounts
    generateRandomWord();
  }, []);

  const generateRandomWord = () => {
    const randomIndex = Math.floor(Math.random() * words.length);
    const word = words[randomIndex].toUpperCase(); // Convert to all caps
    setRandomWord(word);
    // Invoke the callback function with the generated word
    onWordGenerated(word);
  };

  const handleGenerateNewWord = () => {
    generateRandomWord(); // Generate a new random word
  };

  return (
    <div>
      <h2>{randomWord}</h2>
      <button
        style={{
          backgroundColor: '#007bff',
          color: 'white',
          border: 'none',
          padding: '10px 20px',
          borderRadius: '5px',
          cursor: 'pointer',
          boxShadow: '0 2px 4px rgba(0, 123, 255, 0.2)',
          transition: 'background-color 0.3s ease',
        }}
        onClick={handleGenerateNewWord}
      >
        Please generate a different word that would be better for my student
      </button>
    </div>
  );
};

export default RandomWordGenerator;
