import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios'; // Import Axios for making HTTP requests
import { BASE_URL, API_ENDPOINT_GET_STUDENT_BY_NICKNAME_AND_CODE, API_ENDPOINT_GET_STUDENT_BY_NICKNAME } from '../../Shared/Config';
import styles from './index.module.css';
import { logActivity } from '../../Shared/activityLogger';

const Login = ({ OnMessageToParentFromLogin }) => {
  const [students, setStudents] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [showAskFirstName, setAskFirstName] = useState(true);
  const [showAskSecretWord, setAskSecretWord] = useState(false);
  const [nicknameNotFound, setNicknameNotFound] = useState(false);
  const [secretWord, setSecretWord] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessage2, setErrorMessage2] = useState('');

  const secretWordInputRef = useRef(null); // Create a ref for the secret word input

  useEffect(() => {
    if (showAskSecretWord && secretWordInputRef.current) {
      secretWordInputRef.current.focus(); // Focus the input when showAskSecretWord is true
    }
  }, [showAskSecretWord]);

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLoginSuccess = (_id) => {
    logActivity(_id, 3, "Logged In As " + firstName);
    const sentInfo = {
      actionType: "loginSuccess",
      id: _id
    };
    OnMessageToParentFromLogin(JSON.stringify(sentInfo));
  };

  const handleNextClick = async () => {
    if (!firstName.trim()) {
      setErrorMessage("Please enter your name before proceeding.");
      return;
    }
  
    setErrorMessage(''); // Clear error if input is valid
    try {
      const response = await axios.get(`${BASE_URL}${API_ENDPOINT_GET_STUDENT_BY_NICKNAME}?nick_name=${firstName}`);
      if (response.data.length === 0) {
        setAskFirstName(false);
        setAskSecretWord(false);
        setNicknameNotFound(true);
      } else {
        setAskFirstName(false);
        setNicknameNotFound(false);
        setAskSecretWord(true);
      }
    } catch (error) {
      console.error('Error checking student by nickname:', error);
      setErrorMessage('A student with that name was not found. Are you sure you used the right name??'); 
    }
  };

  const handleSecretWordChange = (e) => {
    setSecretWord(e.target.value);
  };

  const handleVerifySecretWord = async () => {
    if (!secretWord.trim()) {
      setErrorMessage2("Please enter your secret word before proceeding.");
      return;
    }
  
    setErrorMessage2(''); // Clear error if input is valid
    try {
      const response = await axios.get(`${BASE_URL}${API_ENDPOINT_GET_STUDENT_BY_NICKNAME_AND_CODE}?nick_name=${firstName}&code=${secretWord}`);
      
      if (response.data.length === 0) {
        console.log("No matches found.");
        setAskFirstName(false);
        setAskSecretWord(false);
        setNicknameNotFound(true);
      } else {
        let student_id = response.data[0].StudentId;
        console.log("User logged in:", student_id);
        handleLoginSuccess(student_id);
      }
    } catch (error) {
      console.error('Error checking student by nickname and code:', error);
      setErrorMessage("An error occurred while verifying your secret word. Please try again.");
    }
  };
  

  const handleGoBack = () => {
    setAskFirstName(true);
    setFirstName('');
    setSecretWord('');
    setAskSecretWord(false);
    setNicknameNotFound(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      const inputName = e.target.getAttribute('data-input-name');
      if (inputName === 'secretWord') {
        handleVerifySecretWord();
      } else if (inputName === 'firstName') {
        handleNextClick();
      }
    }
  };

  return (
    <div className={styles.componentContainer}>   
      <div>
        <img src="/images/LoginLogo.png" alt="Avatar" className='landing-page-container-sparkers-logo' />
        {showAskSecretWord && (
          <div>
            <p>Enter your secret word:</p>
            {errorMessage2 && <p className="errorMessage">{errorMessage2}</p>} 
            <input 
              type="text" 
              value={secretWord} 
              onChange={handleSecretWordChange} 
              className={styles.loginNameInput}
              onKeyDown={handleKeyDown}
              data-input-name="secretWord"
              ref={secretWordInputRef} // Attach the ref to the input
            />
            <br />
            <button className="button4 next-button4" onClick={handleVerifySecretWord}>
              &nbsp;
            </button>
          </div>
        )}

        {nicknameNotFound && (
          <div>
            <p>{`Hmmm...We don't seem to have any matching records. Are you sure that is right?`}</p>
            <button className="primary-button" onClick={handleGoBack}>
              Go Back
            </button>
          </div>
        )}

{showAskFirstName && (
  <div>
    <h2>Hey there, Sparker! <br />What name do you go by in the club?</h2>
    {errorMessage && <p className="errorMessage">{errorMessage}</p>}
    <input 
      type="text" 
      value={firstName} 
      onChange={handleFirstNameChange} 
      className={styles.loginNameInput}
      onKeyDown={handleKeyDown} 
      data-input-name="firstName" 
    />
    <br />
   
    <div className="button4-container" onClick={handleNextClick}>
      <span className="button4-text">NEXT</span>
      <button className="button4 next-button4" onClick={handleNextClick}></button>
    </div>

    <p><strong>Need assistance?</strong> If you have any questions or need support, please contact our team at <a href="mailto:support@sparkers.org">support@sparkers.prg</a>.</p>
    <p className="font-small">This page is designed for existing members of the Sparkers Club web app. If you are not one, you are in the wrong place. Maybe you are looking for <a href="https://www.creatorsonline.org">www.sparkers.org</a></p>
    <p className="font-small">If you ARE supposed to be here, thank you for being a part of Sparkers Club! :)</p>
  </div>
)}
      </div>
    </div>
  );
};

export default Login;
