import React, { useState, useEffect } from 'react';
import { BASE_URL, API_GET_CANNED_TEXT } from '../../../Shared/Config'; 


function HTMLItem({currentPageDataObject}) {
  const [htmlContent, setHtmlContent] = useState('');
  

  useEffect(() => {
    const fetchData = async () => {
      try {

        let cannedTextId = currentPageDataObject.description;
        
        const url = `${BASE_URL}${API_GET_CANNED_TEXT}?canned_text_id=${cannedTextId}`
        const response = await fetch(url);
        const data = await response.json();
        // Assuming only one item is returned from the endpoint
        const content = data[0]?.Content || '';
        setHtmlContent(content);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    
    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    
  );
}

export default HTMLItem;
