import React, { useEffect } from 'react';

const TutorialStep4 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <div className="content-container htmlItem">
      <h2 className="article-title-container">Level Up!</h2>
      <p>You will level up in this journey by coming to this app and following directions.</p>
      <p><span className="article-special-text">This journey is all about learning, and in the Sparkers club, we ignite this process by exploring SPARKS!</span></p>
      <h2>What's a spark, you ask?</h2>
      <p>A spark is anything from an enlightening article to an inspiring video or even a hilarious joke—whatever ignites your creativity and fuels your passion for making things!</p>
      <p><span className="article-special-text">New sparks will show up in this app as we believe you are ready for them</span>. Sometimes you will have new sparks to read…sometimes you won’t. You just need to check in every now and then to find out!</p>
    </div>
  );
};

export default TutorialStep4;
