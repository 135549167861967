import React, { useEffect } from 'react';
import NextContent from '../StudentProfile/NextContent';

const TutorialStep6 = ({ onMessageToParentFromComponent }) => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  const handleClickWithData = (data) => {
    
    console.log("handleClickWithData");
    console.log(data);
      const sentInfo = {
        actionType: data.actionType,
        id: data.id,
        contentType: data.contentType, 
        origin: "tutorial"        
      };
      onMessageToParentFromComponent(sentInfo); // Pass the data to the parent component function
    
    
  };

  return (
    <div className="content-container htmlItem">
      <h2 className="article-title-container">Consume a Spark!</h2>
      <br />
      <div className="green-lightning-container">
      <NextContent loadFinal={(data) => handleClickWithData(data)} />
      </div>
        <br />
        See? You have a spark assigned to you! <span className="article-special-text">Go ahead and consume it</span> (by clicking on the lightening bolt)
    </div>
  );
};

export default TutorialStep6;
