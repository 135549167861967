import React, { useEffect, useState } from 'react';
import axios from 'axios'; // Import Axios for making HTTP requests
import { BASE_URL, API_ENDPOINT_GET_NEXT_CONTENT } from '../../../Shared/Config'; // Assuming you have a config file defining BASE_URL
import { useData } from '../../../Shared/Context';
import { logInformation } from '../../../Shared/Logging';
import SparkerContentConfirm from './SparkerContentConfirm';
import { logActivity } from '../../../Shared/activityLogger';


const NextContent = (props) => {
    const { HandleLoadContent } = props;
    const { loadFinal } = props;
    const { activeStudent } = useData();
    const [availableContent, setAvailableContent] = useState([]); 
    const [showModal, setShowModal] = useState(false);
    const [contentNextTitle, setContentNextTitle] = useState("");

    useEffect(() => {
        fetchNextContent();
      }, []);

      const HandleOnDoItNowClick = async () => {
        //console.log(availableContent);
        var content = availableContent[0];
        let message = activeStudent.FirstName + " began " + content.Title +  " (ContentId: " + content.ContentId + ", StudentAssignedContentId: " + content.StudentAssignedContentId + ")";
        logActivity(activeStudent.StudentId, 4, (message));
        
        handleFinalLoad(content.ContentId, content.ContentType)
      }

      //Load the content after the user confirms they want to read it NOW
      const handleFinalLoad = async (contentId, contentType) => {
     
        const sentInfo = {
          actionType: "loadContent",
          id: contentId,
          contentType: contentType, 
          origin: "home"        
        };
       
        loadFinal(sentInfo);
      }

      const HandleOnDoItLaterClick = async (contentId, contentType) => {
        //setShowConfirm(false);
      }

      const sparkClick1 = async (contentId, contentType) => {
        setShowModal(true);
    };

      const fetchNextContent = async () => {
        try {
            const response = await axios.get(BASE_URL + API_ENDPOINT_GET_NEXT_CONTENT + '?student_id=' + activeStudent.StudentId);
            logInformation("content " + JSON.stringify(response.data[0]), 1);
           
            setAvailableContent(response.data);

            let loadedContent=response.data[0];
         
            setContentNextTitle(loadedContent.Title);
            //setContentLoaded(true);

        
            const sentInfo = {
              actionType: "checkMessages",
              id: 1,
              contentType: "message"
            };
            //HandleLoadContent(sentInfo);
      


          } catch (error) {
            logInformation('Error fetching data:', error);
          }
      }

  return (
    <div>
        {showModal && (<div>

            <div className="modal-overlay">
        <div className="modal-content">
           
            <img
                src="/images/sparkconfirmreadlogo.png"
               
            /><br />
             <b>AND YOUR NEW SPARK IS:</b><br /><br />
            <SparkerContentConfirm
                imageUrl="/images/greenlightning2.png"
                text={contentNextTitle}          
                onDoItNowClick={HandleOnDoItNowClick}
                onDoItLaterClick={HandleOnDoItLaterClick}
            />
            <br />
            <button className='main-button main-button-green' onClick={() => setShowModal(false)}>Consume Spark Later</button>
        </div>
    </div>

        </div>)}




            {availableContent.length === 0 ? 
            <img
            src="/images/nextcontentlogo_gray.png"                  
            className='green-lightning-container'
            style={{ cursor: 'pointer' }}
          />
            
            : <img
            src="/images/nextcontentlogo_green.png"    
            onClick={() => sparkClick1()}              
            className='green-lightning-container'
            style={{ cursor: 'pointer' }} />}
        </div>
  );
};

export default NextContent;
