import { BASE_URL, API_POST_ACTIVITY_LOG } from './Config';

export const logActivity = async (studentId, messageType, messageContent) => {
  try {
    // Remove single quotes to prevent potential SQL injection or parsing issues
    const sanitizedContent = messageContent.replace(/'/g, '');

    const payload = {
      studentId: studentId,
      messageType: messageType,
      messageContent: sanitizedContent
    };

    const url = `${BASE_URL}${API_POST_ACTIVITY_LOG}`; 
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.error('Error logging activity:', error);
    // Optionally re-throw or handle the error as needed
    throw error;
  }
};