import React from 'react';

function ProjectProgressBar({ percentage }) {
  return (
    <div className="progress-bar-container">
       <div>  Project Progress: {percentage}%</div>
      <div
        className="progress-bar"
        style={{
          backgroundImage: `linear-gradient(to right, #FFA500 ${percentage}%, transparent ${percentage}%)`
        }}
      />
    
     
    </div>
  );
}

export default ProjectProgressBar;
