import React, { useEffect } from 'react';

const TutorialStep1 = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <div className="content-container htmlItem">
      <h2 className="article-title-container">🎉 Welcome aboard! 🎉</h2>
      <p>Hey there, Sparker! </p>
        <p>Get ready to embark on an electrifying journey filled with <span className="article-special-text">creativity, innovation, and endless possibilities</span>! We're thrilled to have you join our tribe of young makers and thinkers.</p>
        <p>Our goal is to help you learn how to MAKE COOL THINGS</p>
        <p><b>But first...let's get to know a little bit about our process!</b></p>
    </div>
  );
};

export default TutorialStep1;
