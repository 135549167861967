import HomeGeneric from './Pages/HomeGeneric';
import Home from './Pages/Home';
import Onboarding from './Pages/Onboarding';
import AdminHome from './Pages/Admin';
import LoginRouter from './Pages/LoginRoute';


import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { DataProvider } from '../src/Shared/Context';
import React, { useState, useEffect } from 'react';

function App() {
  const [showAdmin, setShowAdmin] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [inviteCode, setInviteCode] = useState('');
  const [isSparker, setIsSparker] = useState(false);

  useEffect(() => {
    //Check to see if they should be taken to login screen
    const cookies = document.cookie.split(';').reduce((acc, cookie) => {
      const [key, value] = cookie.split('=').map(part => part.trim());
      acc[key] = value;
      return acc;
    }, {});
    setIsSparker(cookies.IsSparker === 'true'); // Check if the IsSparker cookie is set to true

    // Parse query string
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const intent = urlParams.get('intent');
    const code = urlParams.get('code');

    // Check intent
    if (intent === 'invite') {
      setShowOnboarding(true);
      setInviteCode(code || ''); // Set invite code
    } else if (intent === 'admin') {
      setShowAdmin(true);
    }
  }, []);

  return (
    <div className="App">
      <DataProvider>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                showAdmin ? (
                  <AdminHome />
                ) : showOnboarding ? (
                  <Onboarding InviteCode={inviteCode} />
                ) : isSparker ? (
                  <Home />
                ) : (
                  <HomeGeneric />
                )
              }
            />
            <Route path="/login" element={<LoginRouter />} />
          </Routes>
        </Router>
      </DataProvider>
    </div>
  );
}

export default App;
