const is_local = false;

//let url = 'https://sparker8.azurewebsites.net/';
let url = 'https://sparkerfunctions.azurewebsites.net/'

if (is_local){
    url = 'http://localhost:7071/';
}


export const BASE_URL = url;

export const BASE_URLy = 'https://sparker8.azurewebsites.net/';

export const BASE_URLx = 'https://10.0.2.2:7071/api/';
export const LOGGING_ENABLED = false;




export const API_ENDPOINT_GET_STUDENT_BOOKSHELF = 'sparker8-get-student-bookshelf';
export const API_ENDPOINT_GET_EARNED_ACHIEVEMENTS = 'sparker8-get-earned-achievements';
export const API_ENDPOINT_POST_CONTENT_ITEM = 'sparker8-post-content-item';
export const API_ENDPOINT_GET_STUDENT = 'sparker8-get-student';
export const API_ENDPOINT_GET_STUDENT_BY_NICKNAME_AND_CODE = 'sparker8-get-student-by-nickname-and-code';
export const API_ENDPOINT_GET_STUDENT_BY_NICKNAME = 'sparker8-get-student-by-nickname';
export const API_ENDPOINT_GET_NEXT_CONTENT = 'sparker8-get-next-content';
export const API_ENDPOINT_UPGRADE_STUDENT_STATUS = 'sparker8-upgrade-student-status';
export const API_ENDPOINT_GET_PROJECTS_BY_STUDENT = 'sparker8-get-projects-by-student';
export const API_ENDPOINT_GET_PROJECT_STEPS_FOR_STUDENT = 'sparker8-get-project-steps-for-student';
export const API_ENDPOINT_UPDATE_STUDENT_PROJECT_STEP = 'sparker8-update-student-project-step';
export const API_GET_CANNED_TEXT = 'sparker8-get-canned-text';
export const API_GET_PROJECTS_BY_STATUS = 'sparker8-get-projects-by-status';
export const API_GET_PROJECT_BY_STUDENT = 'sparker8-get-project-by-student';
export const API_GET_PROJECTS_BY_STUDENT = 'sparker8-get-projects-by-student';
export const API_GET_QUIZ_QUESTIONS = 'sparker8-get-quiz-questions';
export const API_GET_QUESTION_OPTIONS = 'sparker8-get-quiz-question-options';
export const API_GET_QUIZ_QUESTION = 'sparker8-get-quiz-question';
export const API_POST_ACTIVITY_LOG = 'sparker8-insert-activity-log';





