import React from 'react';
import { useData } from '../../../Shared/Context';
import { BASE_URL } from '../../../Shared/Config';
import styles from './index.module.css';

const ProfileHeader = (props) => {
  const { handleMessageFromChild } = props;
  const { activeStudent } = useData();

  const showSettings = () => {    
    handleMessageFromChild("settings");
  };

  return (
    <div className={styles.ProfileHeaderContainer}>
      
  <div className={styles.object}>
    <div className={styles.left}>
      <img onClick={() => showSettings()} src="/images/profilelogotop.png" className={styles.ProfileHeaderLogo} />
    </div>
    <div className={styles.middle}>
      <div className={styles.ProfileTitleText}>
        {activeStudent.FirstName}
      </div>
      <div className={styles.ProfileTitleSubtext}>
        &nbsp;
      </div>
    </div>
    <div className={styles.right} onClick={() => showSettings()}>
      &nbsp;
    </div>
  </div>
</div>

  );
};



export default ProfileHeader;
