import React, { useEffect, useState } from 'react';
import ArticlePage from './ArticlePage';
import PreviousNextButtons from '../../../../Shared/PreviousNextButtons';
import { BASE_URL } from '../../../../Shared/Config';
import HTMLItem from '../../HTMLItem';
import styles from '../../index.module.css';



const ArticleBase = ({ OnMessageToParentFromComponent, baseData, contentId, isInitialView }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [allPages, setAllPages] = useState([]);
  //const [activePageData, setActivePageData] = useState([]);
  const [showPages, setShowPages] = useState([]);
  const [idOfContent, setIdOfContent] = useState(0);

  useEffect(() => {
    //console.log("Base: " + JSON.stringify(baseData));
    //setActivePageData(baseData[0])
    setIdOfContent(contentId);
    //console.log("Base Data:");
    //console.log(baseData);
    
  }, []); // Dependency array to ensure data updates are reflected
  // useEffect(() => {
    
  //   //console.log("active " + activePageData);
  // }, [activePageData]); // Dependency array to ensure data updates are reflected

  useEffect(() => {
    let pageStatus = [true, false, false, false, false];
    setShowPages(pageStatus);
  }, []); // Dependency array to ensure data updates are reflected

  useEffect(() => {
    setAllPages(baseData);
    //console.log("ALL PAGES ARE");
    //console.log(baseData);
  }, [baseData]); // Dependency array to ensure data updates are reflected

  

  const handleNext = () => {
      let newPage = currentPage + 1;

      let pageStatus = showPages;
      pageStatus[currentPage-1] = false;
      pageStatus[newPage-1] = true;
      setShowPages(pageStatus);

      setCurrentPage(newPage);
  };

const handlePrevNextClick = (info) => {
  if (info=='next'){
    handleNext();
  }
  else{
    handlePrevious();
  }
}

  const handlePrevious = () => {
    let newPage = currentPage - 1;

    let pageStatus = showPages;
    pageStatus[currentPage-1] = false;
    pageStatus[newPage-1] = true;
    setShowPages(pageStatus);

    setCurrentPage(newPage);
};

  const isLastPage = currentPage === allPages.length;

  const handleComplete = () => {
    const sentInfo = {
      actionType: "completeContent",
      id: {contentId}
    };

    //console.log("HERE");
    //console.log({idOfContent});
    OnMessageToParentFromComponent(sentInfo);

   
  };

  return (
    <div className={styles.ArticleBaseContainer}>
      {allPages.length > 0 && ( // Add this condition
        <>
          {allPages.map((pageData, index) => (
  showPages[index] && (
    pageData.TextContent === "htmlItem" ? (
      <HTMLItem
        key={index}
        currentPageDataObject={pageData}
        pageNumber={index + 1}
      />
    ) : (
      <ArticlePage
        key={index}
        currentPageDataObject={pageData}
        pageNumber={index + 1}
      />
    )
  )
))}
<div className="spark-save-button-container">
{isLastPage && isInitialView && (<button className="main-button main-button-green" onClick={handleComplete}>Mark Spark as Complete</button>)}

</div>

<br /><br />

<PreviousNextButtons onClick={handlePrevNextClick} previousVisible={currentPage != 1} nextVisible={currentPage != allPages.length}  />


          
        </>
      )}
    </div>
  );
  
};

export default ArticleBase;
