import React from 'react';

const CodeSample = ({ tableData }) => {
  // Function to replace patterns and style text in the input string
  const processTableData = (data) => {
    // Replace special markers with HTML tags for colors
    return data
      .replace(/#\*qm#\*/g, '"')     // Replace #*qm#* with "
      .replace(/#\*en#\*/g, '\n')    // Replace #*en#* with a line break
      .replace(/#\*sp#\*/g, ' ')     // Replace #*sp#* with a space
      .replace(/#\*beginPurple#\*/g, '<span style="color: pink ;">')
      .replace(/#\*endPurple#\*/g, '</span>')
      .replace(/#\*beginBlue#\*/g, '<span style="color: blue;">')
      .replace(/#\*endBlue#\*/g, '</span>')
      .replace(/#\*beginYellow#\*/g, '<span style="color: yellow;">')
      .replace(/#\*endYellow#\*/g, '</span>');
  };

  // Processed data with HTML tags
  const processedData = processTableData(tableData);

  return (
    <div className="article-code-sample-container">
      <pre dangerouslySetInnerHTML={{ __html: processedData }} /> {/* Use dangerouslySetInnerHTML to render HTML tags */}
    </div>
  );
};

export default CodeSample;
