import React, { useEffect, useState } from 'react';
import { BASE_URL, API_GET_PROJECTS_BY_STUDENT, API_GET_PROJECTS_BY_STATUS } from '../../../Shared/Config'; 
import { useData } from '../../../Shared/Context';
import ProjectItem from './ProjectItem';
import styles from './index.module.css';

const StudentProjects = ({ handleProjectClick, showAllProjects = false }) => {
    const { activeStudent } = useData();
    const [projects, setProjects] = useState([]);

    const fetchProjects = async () => {
        console.log("Fetching projects");
        try {
            if (activeStudent && activeStudent.StudentId) {
                console.log("Fetching projects...");
                const url = BASE_URL + `${API_GET_PROJECTS_BY_STUDENT}?student_id=${activeStudent.StudentId}&status_id=0`;
                console.log(url);
    
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setProjects(data); // Set the fetched data to the state
            } else {
                console.log('Active student is not defined or does not have a StudentId');
            }
        } catch (error) {
            console.error('Error fetching projects:', error);
        }
    };

    useEffect(() => {
        fetchProjects();
    }, [activeStudent]);

    return (
        <div className={styles.ComponentContainer}>   
            {projects.length > 0 ? (
                projects
                .filter(project => showAllProjects || project.CompletionPercentage !== 100)
                    .map(project => (
                        <div key={project.ProjectId}>
                            <ProjectItem
                                projectId={project.ProjectId}
                                projectTitle={project.ProjectTitle}
                                projectDescription={project.ProjectDescription}
                                
                                onProjectClick={handleProjectClick}
                            />
                        </div>
                    ))
            ) : (
                <div></div>
            )}
        </div>
    );
}

export default StudentProjects;
