import React, { useState, useEffect } from 'react';
import { useData } from '../../Shared/Context';
import PreviousNextButtons from '../../Shared/PreviousNextButtons';
import TutorialStep1 from './TutorialStep1';
import TutorialStep2 from './TutorialStep2';
import TutorialStep3 from './TutorialStep3';
import TutorialStep4 from './TutorialStep4';
import TutorialStep5 from './TutorialStep5';
import TutorialStep6 from './TutorialStep6';
import TutorialStep7 from './TutorialStep7';
import TutorialStep8 from './TutorialStep8';
import TutorialHeader2 from './TutorialHeader2';
import TutorialHeader1 from './TutorialHeader1';
import { BASE_URL, API_ENDPOINT_UPGRADE_STUDENT_STATUS } from '../../Shared/Config';
import axios from 'axios';
import ProfileHeader from '../StudentProfile/ProfileHeader';

const Tutorial = ({ onMessageToParentFromComponent, loadScreen, onLogout }) => {
    const { activeStudent } = useData();
    const [currentStep, setCurrentStep] = useState(0);
    const [showSettings, setShowSettings] = useState(false);

    useEffect(() => {
       if (loadScreen==="return"){
        setCurrentStep(6);
       }
    }, []);


    const saveData = async () => {
        try {
          const url=BASE_URL + 'sparker8-upgrade-student-status?student_id=' + activeStudent.StudentId;
          console.log(url);
          const response = await axios.post(url);
          console.log("Saving data...");
          //handleMessageFromChild("hello");
      
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

    const handleLogout = () => {
    
        saveData();
        onLogout();
         
         
       };

    const steps = [
        <div key="step1"><TutorialStep1 /></div>,
        <div key="step2"><TutorialStep2 /></div>,
        <div key="step3"><TutorialStep3 /></div>,
        <div key="step4"><TutorialStep4 /></div>,
        <div key="step5"><TutorialStep5 /></div>,
        <div key="step6"><TutorialStep6 onMessageToParentFromComponent={onMessageToParentFromComponent} /></div>,
        <div key="step7"><TutorialStep7 onMessageToParentFromComponent={onMessageToParentFromComponent} /></div>,
    ];

  

    const handlePrevNextClick = (direction) => {
        if (direction === 'next' && currentStep < steps.length - 1) {
            setCurrentStep(currentStep + 1);
        } else if (direction === 'previous' && currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const handleShowSettings = () => {
        setShowSettings(true);
    };

    return (
        <div>
            
        {(currentStep === 6) && <div>
            <div className="tutorial-header3">
            <img onClick={() => handleShowSettings()} src="../../images/profilelogotop.png" />
        </div>
            </div>}
            {!showSettings &&
            <div>
        <div className="content-container">
           
            {steps[currentStep]}

            {(currentStep === 5) && <PreviousNextButtons onClick={handlePrevNextClick} nextVisible={false} />}
            {(currentStep !== 5) && 
            <div>
                {(currentStep !== 0) && <div> 
                    {(currentStep !== 6) && <div> 
                    
                        <PreviousNextButtons onClick={handlePrevNextClick} />
                
                    </div>}

                    {(currentStep === 6) && <div> <PreviousNextButtons onClick={handlePrevNextClick} nextVisible={false} previousVisible={false} /></div>}
                    
                    
                
                </div>}
                {(currentStep === 0) && <div> <PreviousNextButtons onClick={handlePrevNextClick} previousVisible={false} /></div>}
               
            </div>
            }
        </div>
        </div>}
        {showSettings &&  <div className="content-container htmlItem">
            <p>Excellent. You made it to settings!</p>
            <p>You will come here to modify your account, and log out when you are done for the day.</p>
            <p className="article-special-text">Now all you have to do is log out and log back in!</p>
            <p>What are you waiting for?! Log out and log back in to get started!</p>
            <button className="main-button main-button-green" onClick={() => handleLogout()}>Log Out</button>
            </div>}
        </div>
    );
};

export default Tutorial;
