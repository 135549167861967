import React, { useState, useEffect } from 'react';
import { useData } from '../../Shared/Context';
import { logInformation } from '../../Shared/Logging';
import styles from './index.module.css';
import '../../Shared/styles.css';

////Import Components
import CredentialsManager from '../CredentialsManager';
import StudentProfile from '../StudentProfile';
import ContentItem from '../ContentItem';
import Tutorial from '../Tutorial';
import ProjectHome from '../ProjectHome';




const Home = () => {
    //////Local variables for component visibility logic    
    const [showCredentialsManager, setShowCredentialsManager] = useState(false);
    const [showProfileComponent, setShowProfileComponent] = useState(false);
    const [showViewContent, setShowViewContent] = useState(false);
    const [showTutorialComponent, setShowTutorialComponent] = useState(false);
    const [showProjectComponent, setShowProjectComponent] = useState(false);
    

    ////// Local Variables
    const [loadScreenName, setLoadScreenName] = useState("home"); 
    const [contentDetails, setContentDetails] = useState(null); 
    const [loadedProjectId, setLoadedProjectId] = useState(0);


    //Shared context variables
    const { credentialsGood, setCredentialsGood, activeStudent, setActiveStudent } = useData();


    ///Check to make sure the user is logged in.
    //If the user is not logged in, it will present the user with the login screen
    //If the user is logged in and a new user, it will show them the tutorial
    //Otherwise it will show the user's home screen/profile
    const checkCredentials = () => {
        logInformation("we are checking credentials and..." + credentialsGood, 1);
        if (credentialsGood === true) {
          if (activeStudent.Status === 'PRE') {
            showTutorial();
          
          } else {
            showProfile();
          }      
        } else {
            logInformation("Cred manager", 1);
            showCredentials();
        }
      }
    
      //Check for credentials if the credentialsGood flag is changed
      useEffect(() => {
        checkCredentials();
      }, [credentialsGood]);

      const handleProjectClick = (json) => {
       console.log("!!!!!!!!!!!!!!");
       console.log(json);
      }

      //Handle information coming back from child components
      const handleMessageFromChild = (json) => {
        logInformation("HOME handleMessageFromChild called", 1);
       
        let actionType=json.actionType;
      
        switch (actionType) {
            case "loadContent":
              //alert(activeStudent.StudentId);
              //jbf
              setLoadScreenName(json.origin);         
              setContentDetails(json);
              showContent();
            break;
            case "loadProject":              
              setLoadedProjectId(json.projectId);
              showProject();
            break;
            case "closeProject":
              
              showProfile();
            break;
            case "closeContent":
              if (loadScreenName==="tutorial"){
                setLoadScreenName("return");
                showTutorial();
              }
              else{
                
                showProfile();
              }
              
            break;
            default:
                alert(actionType);
            break;
        }


      }

      //Handle logout request coming from child
      const handleLogout = (logoutData) => {
        logOut();
      };

      const logOut = async () => {
        try {
          localStorage.removeItem("nsf.deviceUserId");
          setShowProfileComponent(false);
          setActiveStudent(null);
          setCredentialsGood(false);
          //setShowBookshelfComponent(false);
          setShowCredentialsManager(false);
          setShowTutorialComponent(false);
        } catch (error) {
          console.error('Error saving data:', error);
        }
      }

      //////Show/Hide components 

      //Show the credentials manager...
      const showCredentials = () => {
        //setShownMessage("");
        setShowCredentialsManager(true);
        setShowProfileComponent(false);
        setShowViewContent(false);
        setShowTutorialComponent(false);
        setShowProjectComponent(false);
        //setShowLogOut(false);
      }

      //Show the Profile
      const showProfile = () => {
        setShowCredentialsManager(false);
        setShowProfileComponent(true);
        setShowViewContent(false);
        //setShowBookshelfComponent(false);
        setShowTutorialComponent(false);
        setShowProjectComponent(false);
        //setShowLogOut(true);    
      }

      //Show the View Content
      const showContent = () => {
        setShowCredentialsManager(false);
        setShowProfileComponent(false);
        setShowViewContent(true);
        //setShowBookshelfComponent(false);
        setShowTutorialComponent(false);
        //setShowProjectComponent(false);
        //setShowLogOut(true);    
      }

      //Show the Tutorial
      const showTutorial = () => {
        setShowCredentialsManager(false);
        setShowProfileComponent(false);
        setShowViewContent(false);
        //setShowBookshelfComponent(false);
        setShowTutorialComponent(true);
        setShowProjectComponent(false);
        //setShowLogOut(true);    
      }

      //Show a Project
      const showProject = () => {

        setShowCredentialsManager(false);
        setShowProfileComponent(false);
        setShowViewContent(false);
        //setShowBookshelfComponent(false);
        setShowTutorialComponent(false);
        setShowProjectComponent(true);
        
        //setShowLogOut(true);    
      }


  return (
    <div className={styles.componentContainer}>   
   
      {showTutorialComponent  && <div><Tutorial loadScreen={loadScreenName} onLogout={handleLogout} onMessageToParentFromComponent={handleMessageFromChild}/></div>}  
      {showViewContent && <div><ContentItem OnMessageToParentFromComponent={handleMessageFromChild} contentDeets={contentDetails}/></div>}
      {showCredentialsManager && <div><CredentialsManager /></div>}
      {showProjectComponent && <div><ProjectHome loadedProjectId={loadedProjectId} onProjectClose={handleMessageFromChild} /></div>}
      
      {showProfileComponent && <div><StudentProfile handleProjectClick={handleMessageFromChild} loadScreen={loadScreenName} onLogout={handleLogout} onMessageToParentFromComponent={handleMessageFromChild}/></div>}
    </div>
  );
};

export default Home;
