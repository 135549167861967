import React, { useEffect, useState } from 'react';
import ProjectStep from './ProjectStep';
import ProjectDetails from './ProjectDetails';
import { useData } from '../../Shared/Context';

const ProjectHome = ({ loadedProjectId, onProjectClose }) => {
    const [showProjectDetails, setShowProjectDetails] = useState(true);
    const [showStep, setShowStep] = useState(false);

    const [chosenStep, setChosenStep] = useState(null);
    const { activeStudent } = useData();

    const onStepSelect = (step) => {
        setChosenStep(step);
        setShowProjectDetails(false);
        setShowStep(true);
    }

    const onStepClose = (completed) => {
        console.log(completed);
        setChosenStep(null);
        setShowProjectDetails(true);
        setShowStep(false);
    }




    return (
        <div className="content-container">
           
           
           {showProjectDetails && <div><ProjectDetails  onProjectClose={onProjectClose} loadedProjectId={loadedProjectId} studentId={activeStudent.StudentId} onStepSelect={onStepSelect} /></div>}
           {showStep && <div><ProjectStep onBack={onStepClose} step={chosenStep} /></div>}
        </div>
    );
};

export default ProjectHome;
